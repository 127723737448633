<template>
    <div class="wrapper">
        <div class="box">
            <img class="top" src="https://static.yihu365.cn/img/h5Img/assets/img/download.png" alt="">
        </div>

    </div>
</template>
<script>
    import {useRouter, useRoute} from 'vue-router'
    export default {
        name: "download",
        setup () {
            let router = useRouter()

            return {
                router
            }
        }
    }
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .wrapper{
        width: 100%;
        height: 100%;
        background: #01C3FF;
        .box{
            box-sizing: border-box;
            width: 100%;

            .top{
                width: 100%;
            }
        }
        .btn{
            position: absolute;
            left: 30%;
            bottom:380px;
            width: 300px;
        }
    }

</style>
