import { render } from "./download.vue?vue&type=template&id=14b32b61&scoped=true"
import script from "./download.vue?vue&type=script&lang=js"
export * from "./download.vue?vue&type=script&lang=js"

import "./download.vue?vue&type=style&index=0&id=14b32b61&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-14b32b61"
/* hot reload */
if (module.hot) {
  script.__hmrId = "14b32b61"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('14b32b61', script)) {
    api.reload('14b32b61', script)
  }
  
  module.hot.accept("./download.vue?vue&type=template&id=14b32b61&scoped=true", () => {
    api.rerender('14b32b61', render)
  })

}

script.__file = "src/views/static/about/download.vue"

export default script